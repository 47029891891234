/**
 * owner : retrAIver
 * author : Manish and Divyangi from Affine
 */

import CryptoJS from "crypto-js";

class helperService {

  secretKey = "XkhZG4fW2t2W";

  encryptData = (text, identifier) => {
    const secretPass = this.secretKey;
    if(text == null) {
      return "";
    }
	  return CryptoJS.AES.encrypt(
		JSON.stringify(text),
		secretPass
	  ).toString();
	};

	decryptData = (text, identifier) => {
    if(text == null) {
      return "";
    }
    const secretPass = this.secretKey;
    const bytes = CryptoJS.AES.decrypt(text, secretPass);
		return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
	};

  setToken = (token) => {
    sessionStorage.setItem("accessToken", token); // make up your own token
  };

  setRefreshToken = (token) => {
    sessionStorage.setItem("refreshToken", token); // make up your own token
  };

  setRole = (role) => {
    sessionStorage.setItem("userRole", this.encryptData(role, "userRole")); // make up your own token
  };

  fetchToken = () => {
    return sessionStorage.getItem("accessToken");
  };

  getRole = () => {
    return this.decryptData(sessionStorage.getItem("userRole"), "userRole").toString();
  };

  fetchRefreshToken = () => {
    return sessionStorage.getItem("refreshToken");
  };

  setQA = (obj) => {
    return sessionStorage.setItem("lsqah", this.encryptData(JSON.stringify(obj), "lsqah"));
  };

  getQA = () => {
    return this.decryptData(sessionStorage.getItem("lsqah"), "lsqah");
  };

  setUserId = (obj) => {
    return sessionStorage.setItem("userId", this.encryptData(obj, "userId"));
  };

  getUserId = () => {
    return this.decryptData(sessionStorage.getItem("userId"), "userId");
  };

  setUserName = (obj) => {
    return sessionStorage.setItem("userName", this.encryptData(obj, "userName"));
  };

  getUserName = () => {
    return this.decryptData(sessionStorage.getItem("userName"), "userName");
  };

  setTemporaryPasswordFlag = (obj) => {
    return sessionStorage.setItem("temporary", this.encryptData(obj, "temporary"));
  };

  getTemporaryPasswordFlag = () => {
    return this.decryptData(sessionStorage.getItem("temporary"), "temporary");
  };

  setFullName = (obj) => {
    return sessionStorage.setItem("fullName", this.encryptData(obj, "fullName"));
  };

  getFullName = () => {
    return this.decryptData(sessionStorage.getItem("fullName"), "fullName");
  };

  setCreditBalance = (amount) => {
    return sessionStorage.setItem("crb", this.encryptData(amount, "crb"));
  }

  getCreditBalance = () => {
    return this.decryptData(sessionStorage.getItem("crb"), "crb");
  };

  setAvailableLicense = (count) =>{
    return sessionStorage.setItem("aul", this.encryptData(count, "aul"));
  }

  getAvailableLicense = () => {
    return this.decryptData(sessionStorage.getItem("aul"), "aul");
  };

  setIndexformat = (index_format) =>{
    return sessionStorage.setItem("indexFormat", this.encryptData(index_format, "indexFormat"));
  }
  getIndexformat  = () => {
    return this.decryptData(sessionStorage.getItem("indexFormat"), "indexFormat");
  };
  setSettingview = (status) =>{
    return sessionStorage.setItem("settingView", this.encryptData(status, "settingView"));
  }
  getSettingview  = () => {
    return this.decryptData(sessionStorage.getItem("settingView"), "settingView");
  };

  setLlamaTemperature = (status) =>{
    return sessionStorage.setItem("llamaTemperature", this.encryptData(status, "llamaTemperature"));
  }
  getLlamaTemperature  = () => {
    return this.decryptData(sessionStorage.getItem("llamaTemperature"), "llamaTemperature");
  };
  setLlamaNoChunks = (status) =>{
    return sessionStorage.setItem("llamaNoChunks", this.encryptData(status, "llamaNoChunks"));
  }
  getLlamaNoChunks= () => {
    return this.decryptData(sessionStorage.getItem("llamaNoChunks"), "llamaNoChunks");
  }
  setLangchainTemperature = (status) =>{
    return sessionStorage.setItem("langchainTemperature", this.encryptData(status, "langchainTemperature"));
  }
  getLangchainTemperature  = () => {
    return this.decryptData(sessionStorage.getItem("langchainTemperature"), "langchainTemperature");
  };
  setLangchainNoChunks = (status) =>{
    return sessionStorage.setItem("langchainNoChunks", this.encryptData(status, "langchainNoChunks"));
  }
  getLangchainNoChunks= () => {
    return this.decryptData(sessionStorage.getItem("langchainNoChunks"), "langchainNoChunks");
  };

  setAlertEmailFrequency = (status) =>{
    return sessionStorage.setItem("alertEmailFrequency", this.encryptData(status, "alertEmailFrequency"));
  }
  getAlertEmailFrequency  = () => {
    return this.decryptData(sessionStorage.getItem("alertEmailFrequency"), "alertEmailFrequency");
  };

  setBalanceAlertThreshold = (status) =>{
    return sessionStorage.setItem("balanceAlertThreshold", this.encryptData(status, "balanceAlertThreshold"));
  }
  getBalanceAlertThreshold  = () => {
    return this.decryptData(sessionStorage.getItem("balanceAlertThreshold"), "balanceAlertThreshold");
  };

  setCategory=(category)=>{
    return sessionStorage.setItem("category", this.encryptData(category, "category"));
  }
  getCategory=()=>{
    return this.decryptData(sessionStorage.getItem("category"), "category");
  }

  setSimilaritySensitivity=(sensitivity)=> {
    return sessionStorage.setItem("SimilaritySensitivity", this.encryptData(sensitivity, "SimilaritySensitivity"));
  }

  getSimilaritySensitivity=()=>{
    return this.decryptData(sessionStorage.getItem("SimilaritySensitivity"), "SimilaritySensitivity");
  }

  checkLicense = () => {
      // check if the license is still valid for the product
      return fetch(process.env.REACT_APP_API_URL+"/licenseService/checkLicense")
        .then((response) => response.json())
        .then((data) => {
          if (data["message"] !== "passed"){
              return data["message"]
          }
          else{
            return "passed"
          }
    });
  }
}

export default new helperService();
