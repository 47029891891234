import { useState } from 'react';
import { useHistory, Link, NavLink  } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import AffineLogo from '../../assets/images/affineLogo.png';
import './Header.scss';
import helper from '../../services/tokenStore';
import useCredit from '../useCredit';
import VelboimLogo from '../../assets/images/velboim_logo.png';

function CollapsibleExample() {
	const history = useHistory();
	const [userFullName,] = useState(helper.getFullName());
	const [userName,] = useState(helper.getUserName());
	const [mobileSideBar, setMobileSideBar] = useState('-100vw');

	const [userRole,] = useState(helper.getRole() ? helper.getRole() : "0");
	const { creditBalance } = useCredit()
	const openCreditPage = () => {
		history.push('/creditHistory');
	}
	const openLandingPage = () => {
		history.push('/landingPage');
	}

	const hideSideBar = () => {
		setMobileSideBar('-100vw');
	}
	const showSideBar = () => {
		setMobileSideBar('0vw');
	}

	return (
		<>
			<div className='mobilesidebar' style={{right: mobileSideBar}}>
				<i style={{fontSize: '32px'}} onClick={() => setMobileSideBar('-100vw')} class="fa fa-times-circle-o" aria-hidden="true"></i>

				<div style={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center", marginTop: '32px' }}>
					<div className="dropbtn" style={{ height: "30px", width: "30px", borderRadius: "50%", backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
						<i style={{color: '#000'}} className="fa-solid fa-user"></i>
					</div>
					<span id="loggedinusername" style={{ marginLeft: "10px" }}>{(userFullName && userFullName != undefined && userFullName != null) ? userFullName : userName}</span>
				</div>

				<div className='creditsbutton' style={{ width: "max-content", height: "32px", marginRight: "20px", borderRadius: "5px", cursor: "pointer", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 10px 0px 5px', marginTop: '16px' }} onClick={() => {openCreditPage(); hideSideBar();}}>
					<label className="credit-text"><i className="fa fa-star mx-2 mt-1 coloredicon" aria-hidden="true" style={{width: "max-content"}}></i>Credit Balance : {creditBalance}</label>
				</div>
				<hr size="10" className='primaryseperator'></hr>

				<Nav className="me-auto">
					{(history.location.pathname == '/qna' || history.location.pathname == '/documents' || history.location.pathname == '/admin-page' || history.location.pathname == '/category') && <NavLink onClick={hideSideBar} className='nav-customcls' to="/qna" activeClassName="active">
						<em className="fa fa-comments me-2" title="Ask Question to AI"></em>
						Ask Eryl
					</NavLink>}
					{userRole.includes('Admin') && (history.location.pathname == '/qna' || history.location.pathname == '/documents' || history.location.pathname == '/admin-page' || history.location.pathname == '/category') &&
						<NavLink onClick={hideSideBar} className='nav-customcls' to="/documents" activeClassName="active">
							<em className="fa fa-file me-2" title="Upload Manual"></em>
							Upload
						</NavLink>}

					{userRole.includes('Admin') && (history.location.pathname == '/qna' || history.location.pathname == '/documents' || history.location.pathname == '/admin-page'|| history.location.pathname == '/category') &&
						<NavLink onClick={hideSideBar} className='nav-customcls' to="/admin-page" activeClassName="active">
							<em className="fa fa-user-cog me-2" title="Admin"></em>
							Admin
						</NavLink>}

					{(userRole.includes('Affine Admin') || userRole.includes('Super Admin')) &&(history.location.pathname == '/qna' || history.location.pathname == '/documents' || history.location.pathname == '/admin-page' || history.location.pathname == '/category') && <NavLink className='nav-customcls' to="/category" onClick={hideSideBar} activeClassName="active">
						<em className="fa fa-list-alt me-2" title="Category"></em>
						Category
					</NavLink>}
					{(!history.location.pathname.includes('/changepassword')) && <div>
					<Link onClick={hideSideBar} className='nav-customcls' to="/changepassword" title="Change Password">
						<i style={{marginRight: '5px'}} className="fa-solid fa-arrow-right-from-bracket fa-key"></i> <span style={{fontSize: "15px"}}>Change Password</span>
					</Link>
					<Link onClick={hideSideBar} className='nav-customcls' to="/login" title="Logout">
						<i style={{marginRight: '5px'}} className="fa-solid fa-arrow-right-from-bracket fa-top-right-icon"></i> <span style={{fontSize: "15px"}}>Logout</span>
					</Link>
					</div>}
				</Nav>
			</div>

		<Navbar className="navbar-custom" collapseOnSelect>
			{(!history.location.pathname.includes('/changepassword')) &&
				<Navbar.Brand className="navbar-cls" href="/QnA-platform/landingpage">
					<img src={AffineLogo} className="d-inline-block align-top logo-cls AffineLogo" alt='Velboim' />
				</Navbar.Brand>
			}
			{(history.location.pathname.includes('/changepassword')) &&
				<Navbar.Brand className="navbar-cls" href="/login">
					<img src={AffineLogo} className="d-inline-block align-top logo-cls AffineLogo" alt='Velboim' />
				</Navbar.Brand>
			}

			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav" className='desktopmenu'>
				<Nav className="me-auto">
					{(history.location.pathname == '/qna' || history.location.pathname == '/documents' || history.location.pathname == '/admin-page' || history.location.pathname == '/category') && <NavLink className='nav-customcls' to="/qna" activeClassName="active">
						<em className="fa fa-comments me-2" title="Ask Question to AI"></em>
						{/* <img className="icon-pos" src={DatasetIcon} alt="Ask AI" > */}
						Ask Eryl
					</NavLink>}
					{userRole.includes('Admin') && (history.location.pathname == '/qna' || history.location.pathname == '/documents' || history.location.pathname == '/admin-page' || history.location.pathname == '/category') &&
						<NavLink className='nav-customcls' to="/documents" activeClassName="active">
							<em className="fa fa-file me-2" title="Upload Manual"></em>
							{/* <img className="icon-pos" src={DatasetIcon} alt="Uplaod Manual" title="Upload Manual
            s"/> */}
							Upload
						</NavLink>}

					{userRole.includes('Admin') && (history.location.pathname == '/qna' || history.location.pathname == '/documents' || history.location.pathname == '/admin-page'|| history.location.pathname == '/category') &&
						<NavLink className='nav-customcls' to="/admin-page" activeClassName="active">
							<em className="fa fa-user-cog me-2" title="Admin"></em>
							{/* <img className="icon-pos" src={DatasetIcon} alt="Uplaod Manual" title="Upload Manual
            s"/> */}
							Admin
						</NavLink>}
					{/* <NavLink className='nav-customcls' to="/category" activeClassName="active">
            <img className='icon-pos' src={TasksIcon} alt="TasksIcon" title="Tasks"/>
            Category
          </NavLink> */}

{/* {userRole.includes('Admin') && (history.location.pathname == '/qna' || history.location.pathname == '/documents' || history.location.pathname == '/admin-page') &&
						<NavLink className='nav-customcls' to="/category" activeClassName="active">
							<em className="fa fa-user-cog me-2" title="Category"></em>
							{/* <img className="icon-pos" src={DatasetIcon} alt="Uplaod Manual" title="Upload Manual
            s"/>
							Category
						</NavLink>} */}

						<Nav className="me-auto">
					{(userRole.includes('Affine Admin') || userRole.includes('Super Admin')) &&(history.location.pathname == '/qna' || history.location.pathname == '/documents' || history.location.pathname == '/admin-page' || history.location.pathname == '/category') && <NavLink className='nav-customcls' to="/category" activeClassName="active">
						<em className="fa fa-list-alt me-2" title="Category"></em>
						{/* <img className="icon-pos" src={DatasetIcon} alt="Ask AI" > */}
						Category
					</NavLink>}
					</Nav>
				</Nav>

				{(history.location.pathname == '/creditHistory') &&
					<Nav>
						<div onClick={() => history.goBack()}>
							<i className="fa fa-arrow-left mx-3 mt-1 coloredicon" aria-hidden="true" title="Back"></i>

						</div>
					</Nav>
				}

				{(history.location.pathname == '/creditHistory') &&
					<Nav>
						<div onClick={openLandingPage}>
							<i className="fa fa-home mx-3 mt-1 coloredicon" aria-hidden="true" title="Home"></i>
						</div>
					</Nav>
				}
				{(history.location.pathname != '/creditHistory') && (!history.location.pathname.includes('/changepassword')) && (history.location.pathname != '/forgot-password') &&
					<Nav>
						<div className='creditsbutton' style={{ width: "max-content", height: "32px", marginRight: "20px", borderRadius: "5px", cursor: "pointer", display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '0 10px 0px 5px' }} onClick={openCreditPage}>
							<label className="credit-text"><i className="fa fa-star mx-2 mt-1 coloredicon" aria-hidden="true" style={{width: "max-content"}}></i>Credit Balance : {creditBalance}</label>
						</div>
					</Nav>}
				{/* <Link disabled className='nav-customcls top-right-icon' to="/support" title="Support" style={{pointerEvents: "none"}}>
            <i disabled className="fa-regular fa-circle-question fa-top-right-icon opacity-50"></i>
          </Link> */}
				{(history.location.pathname != '/creditHistory') && (history.location.pathname != '/forgot-password') &&
					<Nav>
						<div className="dropdown">
							<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
								<div className="dropbtn" style={{ height: "30px", width: "30px", borderRadius: "50%", backgroundColor: "#fff", display: "flex", justifyContent: "center", alignItems: "center" }}>
									<i style={{color: '#000'}} className="fa-solid fa-user"></i>
								</div>
								<span id="loggedinusername" style={{ marginLeft: "10px" }}>{(userFullName && userFullName != undefined && userFullName != null) ? userFullName : userName}</span>
							</div>

							{(!history.location.pathname.includes('/changepassword')) &&
								<div className="dropdown-content" style={{ backgroundColor: "#000", }}>
									<Link className='nav-customcls top-right-icon' to="/changepassword" title="Change Password">
										<i style={{marginRight: '5px'}} className="fa-solid fa-arrow-right-from-bracket fa-key"></i> <span style={{fontSize: "0.85rem"}}>Change Password</span>  </Link>

									<Link className='nav-customcls top-right-icon' to="/login" title="Logout">
										<i style={{marginRight: '5px'}} className="fa-solid fa-arrow-right-from-bracket fa-top-right-icon"></i> <span style={{fontSize: "0.85rem"}}>Logout</span> </Link>

								</div>
							}
						</div>
					</Nav>
				}
			</Navbar.Collapse>

			<i class="fa fa-bars mobilehamicon" onClick={showSideBar} style={{fontSize: '24px'}} aria-hidden="true"></i>

		</Navbar>
		</>
	);
}

export default CollapsibleExample;