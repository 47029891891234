/**
 * owner : retrAIver
 * author : Divyangi from Affine
 */
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./App.scss";
import AppRoutes from "./AppRoutes";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import UserTimeout from "./components/modals/UserTimeout";
import { IdleTimer } from './idledetect'


import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-promise-loader";

import { Tooltip } from "/node_modules/bootstrap/dist/js/bootstrap.esm.min.js";

// This is the APP js file.

class App extends Component {
  constructor (props) {
    super(props)
    this.idleTimer = null
    this.onIdle = this.onIdle.bind(this)
    this.handleChange=this.handleChange.bind(this)
    this.state={user_state:true}
  }

  componentDidMount() {
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
    this.idleTimer.start()
    this.onRouteChanged();
  }
  
  onIdle () {
    if(this.props.location.pathname ==="/login"){
      this.setState({ user_state: true });
    }else{
      this.setState({ user_state: false });
    }
   
  }


  handleChange() {
    this.setState({ user_state:true });
  }
  

  render() {
    let headerComponent = !this.state.isFullPageLayout ? <Header/> : "";
    return (
      <div style={{display: 'flex', flexDirection: 'column'}} className="app">
        {headerComponent}
        <div className="az-content-wrapper" style={{flex : 1, height: 'calc(50vh - 64px) !important'}}>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60*10 }
          promptTimeout={1000 *60*10}
          onPresenceChange={() => {}}
          onIdle={this.onIdle}
          startManually
        />
         
         <AppRoutes  />
        </div>
        {<Footer />}
        <Loader
          promiseTracker={usePromiseTracker}
          color={"#3d5e61"}
          background={"rgba(255,255,255,.5)"}
        />
        {this.state.user_state===false?<UserTimeout handleChange={this.handleChange} />:""}
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = ["/login", "/forgot-password"];
    for (let path of fullPageLayoutRoutes) {
      if (this.props.location.pathname === path) {
        this.setState({
          isFullPageLayout: true,
        });
        document.querySelector(".az-content-wrapper").classList.add("p-0");
        break;
      } else {
        this.setState({
          isFullPageLayout: false,
        });
        document.querySelector(".az-content-wrapper").classList.remove("p-0");
      }
    }
  }
}

export default withRouter(App);
