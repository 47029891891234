import React, { useState, useEffect } from 'react';
import CreditContext from './creditContext';
import api from '../services/interceptor'
import App from '../App';
import helper from '../services/tokenStore'
const CreditProvider = () => {
	const [creditBalance, setCreditBalance] = useState(0);

	useEffect(()=>{

		function getCreditBalance() {
			api.get(`/creditService/getUserCreditBalance/?user_name=${helper.getUserName()}`)
				.then((response) => {
					//helper.setCreditBalance(response.data)
					setCreditBalance(response.data)
				})
				.catch((err) => {
					return;
				});
		}
		getCreditBalance()
	},[])
	return (
		<CreditContext.Provider value={{ creditBalance, setCreditBalance }}> <App /> </CreditContext.Provider>
	)
}

export default CreditProvider