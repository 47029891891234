/**
 * owner : retrAIver
 * author : Manish and Divyangi from Affine
 */
import api from "./interceptor";

class loginService {
  keyCloakLogin(payload) {
    return api.post(`/authenticateService/login`, payload);
  }

  keyCloakForgotPassword(payload) {
    return api.post(`/authenticateService/forgotPassword`, payload);
  }

  keyCloakChangePassword(payload) {
    return api.post(`/authenticateService/changePassword`, payload);
  }

  keyCloakResetPassword(payload) {
    return api.post(`/authenticateService/resetPassword`, payload);
  }

  logout() {
    sessionStorage.removeItem("accessToken");
    sessionStorage.removeItem("userRole");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("fullName");
    sessionStorage.removeItem("temporary");
    sessionStorage.removeItem("userName");
    sessionStorage.removeItem("refreshToken");
    sessionStorage.removeItem("crb");
    sessionStorage.removeItem("lsqah");
    sessionStorage.removeItem("aul");
    sessionStorage.removeItem("indexFormat");
    sessionStorage.removeItem("settingView");
    sessionStorage.removeItem("balanceAlertThreshold");
    sessionStorage.removeItem("SimilaritySensitivity");
    sessionStorage.removeItem("llamaTemperature");
    sessionStorage.removeItem("langchainTemperature");
    sessionStorage.removeItem("llamaNoChunks");
    sessionStorage.removeItem("langchainNoChunks");
    sessionStorage.removeItem("category");
    sessionStorage.removeItem("alertEmailFrequency");
  }
}

export default new loginService();
