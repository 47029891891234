import React, { Component } from 'react'
import affineLogo from '../../assets/images/affine-logo.PNG';
import './Footer.scss';
import { YearFormat } from '../../utils';

export class Footer extends Component {

  YearFormat
  render() {
    return (
      <footer>
          <div  className='left-container'>
            <span className='copy-right'>Copyright <i className="fa-regular fa-copyright"></i> {YearFormat()}</span>
          </div>
          <div className="footertext">  
            <span style={{marginRight: '10px'}} className="powered-by">Powered by </span>
            <a style={{marginRight: '10px'}} href="https://affine.ai" target="_blank"><img className="footerlogopos" src={affineLogo} alt="Affine Logo"/></a>
            <span className="powered-byright">affine.ai </span>
          </div>
      </footer>
    )
  }
}

export default Footer
